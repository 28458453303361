import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent } from "react";

export interface CountryType {
    "data": CountryArry[]
}
export interface CountryArry {

    "id": string,
    "type": string,
    "attributes": {
        "name": string,
        "emoji_flag": string,
        "country_code": string
    }
}
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    isVisibleModal: boolean;
    isAddmodel: boolean;
    valueTab: number,
    checkedItems: { [key: string]: boolean };
    inputValues: { [key: string]: { prefix: string; firstName: string; lastName: string; gender: string; emailId: string; age: string; passportNo: string; nationality: string; countryCode: string; image: string; mobileNo: string } };
    inputErrors: { [key: string]: { prefix: string; firstName: string; lastName: string; gender: string; emailId: string; age: string; passportNo: string; nationality: string; countryCode: string; image: string; mobileNo: string } };
    countBaged: number;
    countsEle: Array<number>
    setFile: string;
    setFileName: string
    countryData: CountryArry[];
    bookingDataAll: any;
    xDateAir: any;
    savedData: any;
    travellerObjData: any;
    checkboxData: any;
    flightCardsArr: { start: string, destination: string }[]
    prefixData: any;
    countrycode: string;
    mobile: string;
    email: string;
    errors: any;
    AirSegment: any;
    AirPricingSolution: any;
    xml_res: string;
    errorInfo: string;
    loading: boolean;
    reviewModal: boolean;
    travellerInfoData: any;
    seatSelectionBox: boolean;
    seatAirItenanry: any;
    seatMatrix: any;
    wholeDataMatrix: any;
    seatsData: any;
    toastOpen: boolean;
    currentCancelIndex: number
    ApiResponseMessage: string;
    topErrorMessage: any;
    currentDateChangeIndex: number
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string | number;
    // Customizable Area End
}

export default class OrderManagementMultiCityController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    pdfApiCallId: string | Message = "";
    countryCodeApiCallId: string = "";
    createBookingApiCallId = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            ApiResponseMessage: "",
            topErrorMessage: "",
            toastOpen: false,
            seatsData: [],
            wholeDataMatrix: [],
            seatAirItenanry: [],
            seatSelectionBox: false,
            travellerInfoData: [{traveler_id:"1",first_name:"Rahul",last_name:"Das",gender:"M"}],
            reviewModal: false,
            loading: false,
            currentDateChangeIndex: 0,
            errorInfo: "",
            countrycode: "",
            mobile: "",
            email: '',
            flightCardsArr: [{ start: 'Delhi', destination: 'Mumbai' }, { start: 'Mumbai', destination: 'Bangaluru' }, { start: 'Bangaluru', destination: 'Delhi' },],
            prefixData: [
                'Mr', 'Ms', 'Master', 'Miss', 'Mrs'
            ],
            errors: {
                countryCode: "",
                mobile: "",
                email: '',
                checkboxAll: ''
            },
            currentCancelIndex: 0,
            checkboxData: [{ id: '1', Traveller: `ADD TRAVELLER 1` }, { id: '2', Traveller: `ADD TRAVELLER 2` }],
            travellerObjData: {},
            savedData: [],
            xDateAir: [],
            bookingDataAll: null,
            countryData: [],
            setFile: "",
            setFileName: "",
            isVisibleModal: false,
            checkedItems: {},
            inputValues: {},
            inputErrors: {},
            isAddmodel: false,
            valueTab: 0,
            countBaged: 1,
            countsEle: [0, 0, 0],
            AirSegment: [],
            AirPricingSolution: {},
            xml_res: "",
            seatMatrix: [
                [{ type: 'availablePaid' }, { type: 'selectedByUser' }, { type: 'booked' }, { type: 'availablePaid' }, { type: 'availablePaid' }, { type: 'availablePaid' }],
                [{ type: 'booked' }, { type: 'booked' }, { type: 'booked' }, { type: 'booked' }, { type: 'booked' }, { type: 'booked' }],
                [{ type: 'availablePaid' }, { type: 'availablePaid' }, { type: 'availablePaid' }, { type: 'availablePaid' }, { type: 'availablePaid' }, { type: 'availablePaid' }],
                [{ type: 'booked' }, { type: 'booked' }, { type: 'availablePaid' }, { type: 'clicked' }, { type: 'clicked' }, { type: 'booked' }],
                [{ type: 'availablePaid' }, { type: 'availablePaid' }, { type: 'availablePaid' }, { type: 'reservation' }, { type: 'free' }, { type: 'free' }],
                [{ type: 'free' }, { type: 'free' }, { type: 'free' }, { type: 'availablePaid' }, { type: 'availablePaid' }, { type: 'paidAvailable' }],
                [{ type: 'paidAvailable' }, { type: 'booked' }, { type: 'availablePaid' }, { type: 'availablePaid' }, { type: 'availablePaid' }, { type: 'booked' }],
                [{ type: 'booked' }, { type: 'booked' }, { type: 'booked' }, { type: 'booked' }, { type: 'free' }, { type: 'free' }],
                [{ type: 'free' }, { type: 'free' }, { type: 'booked' }, { type: 'booked' }, { type: 'booked' }, { type: 'booked' }],
                [{ type: 'free' }, { type: 'booked' }, { type: 'free' }, { type: 'occupied' }, { type: 'occupied' }, { type: 'occupied' }],
                [{ type: 'booked' }, { type: 'booked' }, { type: 'free' }, { type: 'free' }, { type: 'booked' }, { type: 'free' }],
                [{ type: 'free' }, { type: 'free' }, { type: 'free' }, { type: 'booked' }, { type: 'booked' }, { type: 'free' }],
            ]
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Received", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId != null && apiRequestCallId === this.countryCodeApiCallId && this.countryCodeApiCallId != null) {
                this.handleGetAllCountryList(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.countrylistDataApi();
    }

    cancelationFessApply = () => {
        this.setState({
            isVisibleModal: true,
        })
    }

    handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, checked } = event.target;
        const travellerId = name.replace('checkbox', '');
        this.setState((prevState) => ({
            checkedItems: {
                ...prevState.checkedItems,
                [name]: checked,
            },
            savedData: {
                ...prevState.savedData,
                [travellerId]: {
                    ...prevState.savedData[travellerId],
                    isSaved: !checked ? false : prevState.savedData[travellerId]?.isSaved,
                },
            },

        }));
    };
    handleAddModel = () => {
        this.setState({ isAddmodel: true })
    }
    handleFileChange = (event: ChangeEvent<HTMLInputElement>, idFile: number) => {
        const file = event.target.files?.[0];
        if (file) {
            this.setState((prevState) => ({
                inputValues: {
                    ...prevState.inputValues,
                    [idFile]: { ...prevState.inputValues[idFile], image: file.name },
                },
                inputErrors: {
                    ...prevState.inputErrors,
                    [idFile]: { ...prevState.inputErrors[idFile], image: false },
                },
                setFileName: file.name,
            }));
        }
    };
    resetReviewModal = () => {
        this.setState({ reviewModal: false })
    }
    confirmSeatSelection = () => {
        this.setState({ reviewModal: false, seatSelectionBox: true })
    }

    handleBrowseClick = (idElemt: number): void => {
        const element = document.getElementById(`upload-file-${idElemt}`) as HTMLInputElement | null;
        if (element) {
            element.click();
        }
    };
    handleSaveClick = () => {

    }
    handleShowCancelCharge = (index: number) => {
        this.setState({ currentCancelIndex: index })
    }
    handleShowDateChange = (index: number) => {
        this.setState({ currentDateChangeIndex: index })
    }
    handleInputChange = (event: ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>, idInput: number, inputType: string) => {
        const { value } = event.target;

        this.setState((prevState) => {
            const updatedErrors: any = {
                ...prevState.inputErrors,
                [idInput]: {
                    ...prevState.inputErrors[idInput],
                    [inputType]: value.trim() === ''
                }
            };

            const hasErrors = Object.values(updatedErrors[idInput]).some((error) => error);

            return {
                inputValues: {
                    ...prevState.inputValues,
                    [idInput]: {
                        ...prevState.inputValues[idInput],
                        [inputType]: value
                    }
                },
                inputErrors: updatedErrors,
                topErrorMessage: hasErrors ? 'Please fill all required fields.' : ''
            };
        });
    };

    handleGetAllCountryList = (responseJson: CountryType) => {
        if (responseJson) {
            this.setState({ countryData: responseJson.data })
        }
    }
    countrylistDataApi = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.countryCodeApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.apiEndPointGetCountry
        );

        const header = {
            "Content-Type": configJSON.apiContentType,
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.listOfOrdersMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    setCountryCode = (text: string) => {
        this.setState({
            countrycode: text,
            errors: { ...this.state.errors, countrycode: '' },
        });
    };
    setMobile = (text: string) => {
        this.setState({
            mobile: text,
            errors: { ...this.state.errors, mobile: '' },
        });
    };
    setEmail = (text: string) => {
        this.setState({
            email: text,
            errors: { ...this.state.errors, email: '' },
        });
    };
    resetaddModal = () => {
        this.setState({
            isAddmodel: false,
        })
    }
    handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ valueTab: newValue });
    };
    decreaseCount = (index: number) => {
        this.setState((prevState) => {
            const newCounts = [...prevState.countsEle];
            if (newCounts[index] > 1) {
                newCounts[index] -= 1;
            } else {
                newCounts[index] = 0;
            }
            return { countsEle: newCounts };
        });
    };
    increaseCount = (index: number) => {
        this.setState((prevState) => {
            const newCounts = [...prevState.countsEle];
            if (newCounts[index] === 0) {
                newCounts[index] = 1;
            } else {
                newCounts[index] += 1;
            }
            return { countsEle: newCounts };
        });
    };
    resetModal = () => {
        this.setState({
            isVisibleModal: false,
            currentCancelIndex: 0,
            currentDateChangeIndex: 0
        })
    }
    handleSubmitOrderMgmt = () => {
       this.setState({reviewModal:true})
    }
    // Customizable Area End
}
